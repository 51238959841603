import React from "react";
import { Link } from "react-router-dom";
import { useState,useEffect } from "react";

import {Helmet} from "react-helmet";
const About=() =>{
 const[item,setItem]=useState([]);
useEffect(() =>{

fetch("https://aboutcontentwriting.com/admin/api/about.php")
.then(res=>res.json())
.then(
(result)=>{

     
    setItem(result);

}

)

},[]

)
return (
<>
<Helmet>
{item.map(item =>(
            <title>{item.meta_title}</title>
            
            ))}
            {item.map(item =>(
                <meta name="description" content={item.meta_description}></meta>
            ))}
              {item.map(item =>(
                <meta name="keywords" content={item.meta_keyword}></meta>
            ))}
        </Helmet>
<div class="container-fluid position-relative p-0">
         

         <div class="container-fluid bg-primary py-5 bg-header" style={{marginBottom: "90px"}}>
             <div class="row py-5">
                 <div class="col-12 pt-lg-5 mt-lg-5 text-center">
                     <h1 class="display-4 text-white animated zoomIn">About </h1>
                     <a href="" class="h5 text-white">Home</a>
                     <i class="fa fa-circle text-white px-2"></i>
                     <a href="" class="h5 text-white">About</a>
                 </div>
             </div>
         </div>
     </div>

     <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
<div class="container py-5">
 
 {item.map(item =>(
    <p dangerouslySetInnerHTML={{ __html: item.page_content }} />
  
 ))}
</div>
</div>
 

 

</>
)
}

export  default About;