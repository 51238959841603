import About from "./Pages/About";
import Blog from "./Pages/Blog";
import Contact from "./Pages/Contact";
import React, { Component } from 'react';
import Home from "./Pages/Home";
import { Routes,Route } from "react-router-dom";
import { Link } from "react-router-dom";
import Footer from "./Pages/Footer";
import Header from "./Pages/Header";
import Postpage from "./Pages/Postpage";
import 'font-awesome/css/font-awesome.min.css';

 
function App() {
  
  return (
    
    <div className="App">
      
      <Header/>
      <Routes>

<Route path="/" element={ <Home/>}/>
<Route path="/about-us" element={ <About/>}/>
<Route path="/blog" element={ <Blog/>}/>
<Route path="/post/:postId" element={ <Postpage/>}/>
<Route path="/contact-us" element={ <Contact/>}/>

      </Routes>
     <Footer/>
     
    </div>
  );
}

export default App;
