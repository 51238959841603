import React from "react";
 
import { useState,useEffect } from "react";
import { Link } from "react-router-dom";
const Footer=() =>{
   
    const[item,setItem]=useState([]);
useEffect(() =>{

fetch("https://aboutcontentwriting.com/admin/api/setting.php")
.then(res=>res.json())
.then(
(result)=>{

     
    setItem(result);

}

)

},[]

)
const[social,setItems]=useState([]);
useEffect(() =>{

fetch("https://aboutcontentwriting.com/admin/api/sociallink.php")
.then(res=>res.json())
.then(
(result)=>{

     
    setItems(result);

}

)

},[]

)
return (
<>
<div className="container-fluid bg-dark text-light mt-5 wow fadeInUp" data-wow-delay="0.1s">
<div className="container">
<div className="row gx-5">
<div className="col-lg-4 col-md-6 footer-about">
<div className="d-flex flex-column align-items-center justify-content-center text-center h-100 bg-primary p-4">
<a href="index.html" className="navbar-brand">
<h1 className="m-0 text-white"><img className="w-100" src="assets/img/rankwlogo.png" alt="rankingworlddigital" style={{height:"125px"}}/></h1>
</a>


{item.map(item =>(
 
 <p dangerouslySetInnerHTML={{ __html: item.footer_about }} />
 

  
))}
</div>
</div>
<div className="col-lg-8 col-md-6">
<div className="row gx-5">
<div className="col-lg-6 col-md-12 pt-5 mb-5">
<div className="section-title section-title-sm position-relative pb-3 mb-4">
<h3 className="text-light mb-0">Get In Touch</h3>
</div>
<div className="d-flex mb-2">
<i className="bi bi-geo-alt text-primary me-2"></i>
{item.map(item =>(
<p className="mb-0">{item.contact_address}</p>))}
</div>
<div className="d-flex mb-2">
<i className="bi bi-envelope-open text-primary me-2"></i>
{item.map(item =>(
<p className="mb-0">{item.contact_email}</p>))}
</div>
<div className="d-flex mb-2">

 

</div>
<div className="d-flex mt-4">
{social.map(social =>(
  <a className="btn btn-primary btn-square me-2" href={social.social_url} target="_blank"><i className={social.social_icon}></i></a>
 
))}
</div>
</div>
 
<div className="col-lg-6 col-md-12 pt-0 pt-lg-5 mb-5">
<div className="section-title section-title-sm position-relative pb-3 mb-4">
<h3 className="text-light mb-0">Popular Links</h3>
</div>
<div className="link-animated d-flex flex-column justify-content-start">
<Link to="/" className="text-light mb-2" onClick={() => {
    window.scroll(0, 0);
  }}>Home</Link>
 <Link to="/about-us" className="text-light mb-2" onClick={() => {
    window.scroll(0, 0);
  }}>About</Link>
  <Link to="/contact-us" className="text-light mb-2" onClick={() => {
    window.scroll(0, 0);
  }}>Contact </Link>
  <Link to="/blog" className="text-light mb-2" onClick={() => {
    window.scroll(0, 0);
  }}>Blog</Link>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
<div className="container-fluid text-white" style={{background: "#061429"}}>
<div className="container text-center">
<div className="row justify-content-end">
<div className="col-lg-8 col-md-6">
<div className="d-flex align-items-center justify-content-center" style={{height: "75px"}}>
<p className="mb-0">&copy; <a className="text-white border-bottom" href="#">About Content Writing</a>. All Rights Reserved. 


Design & Developed  by <a className="text-white border-bottom" href="https://onedizitalz.com/" target="_blank">One Dizitalz Pvt Ltd</a></p>
</div>
</div>
</div>
</div>
</div>
 

<a href="#" className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"><i className="bi bi-arrow-up"></i></a>
</>
)
}

export  default Footer;