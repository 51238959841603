import React from "react";
import {Helmet} from "react-helmet";
import { useState,useEffect } from "react";
import { Link } from "react-router-dom";
 import emailjs from "emailjs-com";
import { Form, Input, TextArea, Button } from "semantic-ui-react";
 
import Swal from "sweetalert2";
 const SERVICE_ID = "service_gzw5sic";
const TEMPLATE_ID = "template_sm5v3f7";
const PUBLIC_KEY = "LVfFw7KJN_uwL6ruy";
const Home=() =>{
    const[item,setItem]=useState([]);
useEffect(() =>{

fetch("https://aboutcontentwriting.com/admin/api/activeslider.php")
.then(res=>res.json())
.then(
(result)=>{     
setItem(result);
}
)
},[]

)

const[testim,setItemtest]=useState([]);
useEffect(() =>{

fetch("https://aboutcontentwriting.com/admin/api/testimonial.php")
.then(res=>res.json())
.then(
(result)=>{     
  setItemtest(result);
}
)
},[]

)
const[items,setItems]=useState([]);
useEffect(() =>{

fetch("https://aboutcontentwriting.com/admin/api/slider.php")
.then(res=>res.json())
.then(
(result)=>{     
setItems(result);
}
)
},[]

)

const[blog,setItemblog]=useState([]);
useEffect(() =>{

fetch("https://aboutcontentwriting.com/admin/api/homeblog.php")
.then(res=>res.json())
.then(
(blogdata)=>{     
setItemblog(blogdata);
}
)
},[]

)
const[htitle,setItemti]=useState([]);
useEffect(() =>{

fetch("https://aboutcontentwriting.com/admin/api/setting.php")
.then(res=>res.json())
.then(
(result)=>{

     
  setItemti(result);

}

)

},[]

)
    const handleOnSubmit = (e) => {
        e.preventDefault();
        emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY)
          .then((result) => {
            console.log(result.text);
            Swal.fire({
              icon: 'success',
              title: 'Message Sent Successfully'
            })
          }, (error) => {
            console.log(error.text);
            Swal.fire({
              icon: error,
              title: error,
              text: error.text,
            })
          });
        e.target.reset()
      };
return (
<>
<Helmet>
{htitle.map(htitle =>(
            <title>{htitle.meta_title_home} </title>
))}
 {htitle.map(htitle =>(
                <meta name="description" content={htitle.meta_description_home}></meta>
            ))}
              {htitle.map(htitle =>(
                <meta name="keywords" content={htitle.meta_keyword_home}></meta>
            ))}
        </Helmet>


<div class="container-fluid position-relative p-0">
<div id="header-carousel" class="carousel slide carousel-fade" data-bs-ride="carousel">
<div class="carousel-inner">
{item.map(item =>(
<div class="carousel-item active">
<img class="w-100" src={item.photo} alt="rankingworlddigital"/>
<div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
<div class="p-3">
<h2 class="text-white text-uppercase mb-3 animated slideInDown">{item.heading}</h2>
<p class="display-1 text-white mb-md-4 animated zoomIn"style={{fontSize:"18px"}}>{item.content}</p>
  
<Link to="/blog" class="btn btn-outline-light py-md-3 px-md-5 animated slideInRight ">Content Writing Blog</Link>
</div>
</div>
</div>
))}
{items.map(items =>(
<div class="carousel-item">
<img class="w-100" src={items.photo} alt="rankingworlddigital"/>
<div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
<div class="p-3">
<h2 class="text-white text-uppercase mb-3 animated slideInDown">{items.heading}</h2>
<p class="display-1 text-white mb-md-4 animated zoomIn" style={{fontSize:"18px"}}>{items.content}</p>
 
 
 
<Link to="/blog" class="btn btn-outline-light py-md-3 px-md-5 animated slideInRight ">Content Writing Blog</Link>
</div>
</div>
</div>

))}
</div>
 </div>
</div>

 

<div class="container-fluid facts py-5 pt-lg-0">
<div class="container py-5 pt-lg-0">
<div class="row gx-0">
<div class="col-lg-4 wow zoomIn" data-wow-delay="0.1s">
<div class="bg-primary shadow d-flex align-items-center justify-content-center p-4" style={{height: "150px"}}>
<div class="bg-white d-flex align-items-center justify-content-center rounded mb-2" style={{height: "60px",width: "60px"}}>
<i class="fa fa-users text-primary"></i>
</div>
<div class="ps-4">
<h5 class="text-white mb-0">Storytelling for breakfast</h5>
 
</div>
</div>
</div>
<div class="col-lg-4 wow zoomIn" data-wow-delay="0.3s">
<div class="bg-light shadow d-flex align-items-center justify-content-center p-4"style={{height: "150px"}}>
<div class="bg-primary d-flex align-items-center justify-content-center rounded mb-2" style={{height: "60px",width: "60px"}}>
<i class="fa fa-check text-primary"></i>
</div>
<div class="ps-4">
<h5 class="text-primary mb-0">Engaging Content for Lunch </h5>
 
</div>
</div>
</div>
<div class="col-lg-4 wow zoomIn" data-wow-delay="0.6s">
<div class="bg-primary shadow d-flex align-items-center justify-content-center p-4"style={{height: "150px"}} >
<div class="bg-white d-flex align-items-center justify-content-center rounded mb-2"style={{height: "60px",width: "60px"}}>
<i class="fa fa-check text-white"></i>
</div>
<div class="ps-4">
<h5 class="text-white mb-0">Creative Writing for Dinner</h5>
 
</div>
</div>
</div>
</div>
</div>
</div>


<div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
<div class="container py-5">
<div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{maxWidth: "600px"}}>
<h5 class="fw-bold text-primary text-uppercase">Latest Blog</h5>
<h1 class="mb-0">Read The Latest Articles from Our Blog Post</h1>
</div>
<div class="row g-5">
{blog.map(blog =>(
<div class="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
<div class="blog-item bg-light rounded overflow-hidden">
<div class="blog-img position-relative overflow-hidden">
<Link  to={'/post/'+blog.news_slug}  onClick={() => {
    window.scroll(0, 0);
  }}><img class="img-fluid" src={blog.photo} alt=""/></Link>
 
</div>
<div class="p-4">
<div class="d-flex mb-3">
<small class="me-3"><i class="fa fa-user" aria-hidden="true"></i>
{blog.publisher}</small>
<small><i class="fa fa-calendar" aria-hidden="true"></i>
{blog.news_date}</small>
</div>
<Link  to={'/post/'+blog.news_slug}  onClick={() => {
    window.scroll(0, 0);
  }}><h4 class="mb-3">{blog.news_title}</h4></Link>
 
<Link  to={'/post/'+blog.news_slug}  onClick={() => {
    window.scroll(0, 0);
  }}>Read More <i className="bi bi-arrow-right"></i></Link>
</div>
</div>
</div>
))}

</div>
</div>
</div>


<div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
<div class="container py-5">
<div class="row g-5">
<div class="col-lg-7">
<div class="section-title position-relative pb-3 mb-5">
<h5 class="fw-bold text-primary text-uppercase">About Me</h5>
<h1 class="mb-0">Patricia Sadri</h1>
</div>
<p class="mb-4">I started writing when I was in the 4th grade. I loved writing stories and poems. Cooking up plots, naming characters, writing poetry, and playing with words. I’ve studied creative writing and content writing.</p>
<p>Websites and blogs are among my favourites when I write for brands.</p>
<div class="row g-0 mb-3">
<div class="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
<h5 class="mb-3"><i class="fa fa-check text-primary me-3"></i>Content Writer</h5>
<h5 class="mb-3"><i class="fa fa-check text-primary me-3"></i>Blog Writer</h5>
</div>
<div class="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
<h5 class="mb-3"><i class="fa fa-check text-primary me-3"></i>SEO Writer</h5>
<h5 class="mb-3"><i class="fa fa-check text-primary me-3"></i>Website Writer</h5>
</div>
</div>
<Link to="/about-us" class="btn btn-primary py-3 px-5 mt-3 wow zoomIn" onClick={() => {window.scroll(0, 0);}}>Read More</Link>
 
</div>
<div class="col-lg-5" >
<div class="position-relative h-100">
<img class="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src="assets/img/about.jpeg" style={{objectFit: "cover"}} />
</div>
</div>
</div>
</div>
</div>

<div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
<div class="container py-5">
<div class="section-title text-center position-relative pb-3 mb-5 mx-auto"style={{maxWidthh: "600px"}}>
<h5 class="fw-bold text-primary text-uppercase">The Content of it all</h5>
<h1 class="mb-0">Why is Content Writing tricky?</h1>
</div>
<div class="row g-5">
<div class="col-lg-4">
<div class="row g-5">
<div class="col-12 wow zoomIn" data-wow-delay="0.2s">
<div class="rounded d-flex align-items-center justify-content-center mb-3"style={{width: "60px", height: "60px"}}>
<img  src="assets/img/contentstatic.png" style={{width: "60px", height: "60px"}} />
</div>
<h4> 	Conten-tastic</h4>
<p class="mb-0">Creating Creative Content that is meaningful to your audience. A real sweat.</p>
</div>
<div class="col-12 wow zoomIn" data-wow-delay="0.6s">
<div class="rounded d-flex align-items-center justify-content-center mb-3" style={{width: "60px", height: "60px"}}>
<img  src="assets/img/abdu.png" style={{width: "60px", height: "60px"}} />
</div>
<h4> 	Au-be-dience</h4>
<p class="mb-0">Content is more about what the AUDIENCE wants to read. Centre of our world.</p>
</div>
</div>
</div>
<div class="col-lg-4  wow zoomIn" data-wow-delay="0.9s"style={{minheight: "350px"}}>
<div class="position-relative h-100">
<img class="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.1s" src="assets/img/feature.jpg" style={{objectfit: "cover"}} />
</div>
</div>
<div class="col-lg-4">
<div class="row g-5">
<div class="col-12 wow zoomIn" data-wow-delay="0.4s">
<div class="rounded d-flex align-items-center justify-content-center mb-3"style={{height: "60px",width: "60px"}}>
<img  src="assets/img/editor.png" style={{width: "60px", height: "60px"}} />
</div>
<h4> 	Editor-onomy</h4>
<p class="mb-0">The aftermath of writing contentis an overthinking game of EDITING</p>
</div>
<div class="col-12 wow zoomIn" data-wow-delay="0.8s">
<div class=" rounded d-flex align-items-center justify-content-center mb-3"style={{height: "60px",width: "60px"}}>
<img  src="assets/img/chat.png" style={{width: "60px", height: "60px"}} />
</div>
<h4> 	Feed-back-ache</h4>
<p class="mb-0">They may love what you write, or throw you a harsh REVIEW. That’s life!</p>
</div>
</div>
</div>
</div>
</div>
</div>

<div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
<div class="container py-5">
<div class="section-title text-center position-relative pb-3 mb-5 mx-auto"style={{maxWidth: "600px"}}>
<h5 class="fw-bold text-primary text-uppercase">Content Writing Tips</h5>
<h1 class="mb-0">Improve your writing style so they’ll hire you again</h1>
</div>
<div class="row g-5">
<div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
<div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
<div class="service-icon">
<i class="fa fa-code text-white"></i>
</div>
<h4 class="mb-3">Basic Practice</h4>
<p class="m-0">Spend 20 minutes writing one page every day – pick any topic.</p>
 
 
</div>
</div>
<div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
<div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
<div class="service-icon">
<i class="fa fa-code text-white"></i>
</div>
<h4 class="mb-3"> 	High-Level Practice</h4>
<p class="m-0">Re-write your own content with a pretend audience.</p>
 
 
</div>
</div>
<div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
<div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
<div class="service-icon">
<i class="fa fa-code text-white"></i>
</div>
<h4 class="mb-3"> 	Torture zone</h4>
<p class="m-0">Be your own Critic and Client – what would you do if the roles were reversed?</p>
 
</div>
</div>
<div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
<div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
<div class="service-icon">
<i class="fa fa-android text-white"></i>
</div>
<h4 class="mb-3"> 	Fresh Perspective</h4>
<p class="m-0">Study new writing trends and listen to content-writing podcasts</p>
 
</div>
</div>
<div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
<div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
<div class="service-icon">
<i class="fa fa-search text-white"></i>
</div>
<h4 class="mb-3"> 	Write-kind of Stalker</h4>
<p class="m-0">Follow experienced content writers and their work</p>
 
</div>
</div>
<div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
<div class="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
<div class="service-icon">
<i class="fa fa-search text-white"></i>
</div>
<h4 class="mb-3"> 	 	Woke Writer</h4>
<p class="m-0">Stay updated with fresh content writing styles (read books, social media, etc.)</p>
 
</div>
</div>
</div>
</div>
</div>

 
<div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
<div class="container py-5">
<div class="row g-5">
<div class="col-lg-7">
<div class="section-title position-relative pb-3 mb-5">
<h5 class="fw-bold text-primary text-uppercase">Be My Guest</h5>
<h1 class="mb-0">Share your experience and learnings about Content Writing.</h1>
</div>
<div class="row gx-3">
 

</div>
 
<div class="d-flex align-items-center mt-2 wow zoomIn" data-wow-delay="0.6s">
 
<div class="ps-4">
 
</div>
</div>
</div>
<div class="col-lg-5">
<div class="bg-primary rounded h-100 d-flex align-items-center p-5 wow zoomIn" data-wow-delay="0.9s">
  <div style={{width:"100%"}}>
  <Form onSubmit={handleOnSubmit}>
        <Form.Field
          id='form-input-control-email'
          control={Input}
          label='Email'
          name='user_email'
          placeholder='Email…'
          required
          
          
        />
        <Form.Field
          id='form-input-control-last-name'
          control={Input}
          label='Name'
          name='user_name'
          placeholder='Name…'
          required
          
          
        />
        <Form.Field
          id='form-input-control-last-name'
          control={Input}
          label='Mobile'
          name='user_phone'
          placeholder='Mobile…'
          required
          
           
        />
        <Form.Field
          id='form-input-control-last-name'
          control={Input}
          label='Topic'
          name='user_topic'
          placeholder='Topic…'
          required
          
          
        />
        <Form.Field
          id='form-textarea-control-opinion'
          control={TextArea}
          label='Message'
          name='user_message'
          placeholder='Message…'
          required
        />
        <Button type='submit' color='green'>Submit</Button>
      </Form></div>
</div>
</div>
</div>
</div>
</div>

 

 





  
 
 
 





</>


)


}
export default Home;