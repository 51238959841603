import React from "react";
import { Link } from "react-router-dom";
 
import { useState,useEffect } from "react";
import {Helmet} from "react-helmet";

const Blog=() =>{
const[item,setItem]=useState([]);
useEffect(() =>{

fetch("https://aboutcontentwriting.com/admin/api/bloglist.php")
.then(res=>res.json())
.then(
(result)=>{

     
    setItem(result);

}

)

},[]

)
  


return (
<>
<Helmet>
            <title>Blog - yoursite.com</title>
            <meta name="description" content="Lorem ipsum dolor sit amet" />
        </Helmet>
<div className="container-fluid position-relative p-0">
         
 
         <div className="container-fluid bg-primary py-5 bg-header" style={{marginBottom: "90px"}}>
             <div className="row py-5">
                 <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                     <h1 className="display-4 text-white animated zoomIn">Blog</h1>
                     <a href="" className="h5 text-white">Home</a>
                     <i className="fa fa-circle text-white px-2"></i>
                     <a href="" className="h5 text-white">Blog</a>
                 </div>
             </div>
         </div>
     </div>

      
 <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
<div className="container py-5">


    
<div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{maxWidth: "600px"}}>
<h5 className="fw-bold text-primary text-uppercase">Latest Blog</h5>
<h1 className="mb-0">Read The Latest Articles from Our Blog Post</h1>
</div>


<div className="row g-5">

{item.map(item =>(
     
    <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
<div className="blog-item bg-light rounded overflow-hidden">
<div className="blog-img position-relative overflow-hidden">
<Link to={'/post/'+item.news_slug}  onClick={() => {
    window.scroll(0, 0);
  }}><img className="img-fluid" src={item.photo} alt=""/></Link>
 
</div>
<div className="p-4">
<div className="d-flex mb-3">
<small className="me-3"><i className="far fa-user text-primary me-2"></i>{item.publisher}</small>
<small><i className="far fa-calendar-alt text-primary me-2"></i>{item.news_date}</small>
</div>
<Link  to={'/post/'+item.news_slug}  onClick={() => {
    window.scroll(0, 0);
  }}><h4 className="mb-3">{item.news_title}</h4></Link>
 
<Link to={'/post/'+item.news_slug}  onClick={() => {
    window.scroll(0, 0);
  }}>Read More <i className="bi bi-arrow-right"></i></Link>
</div>
</div>
</div>

))}



 
 
 
</div>
</div>
</div>
 


</>
)
}

export  default Blog;