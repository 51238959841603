import React from "react";
import { Link } from "react-router-dom";
import { useState,useEffect } from "react";

const Header=() =>{
    const[item,setItem]=useState([]);
useEffect(() =>{

fetch("https://aboutcontentwriting.com/admin/api/setting.php")
.then(res=>res.json())
.then(
(result)=>{

     
    setItem(result);

}

)

},[]

)
const[social,setItems]=useState([]);
useEffect(() =>{

fetch("https://aboutcontentwriting.com/admin/api/sociallink.php")
.then(res=>res.json())
.then(
(result)=>{

     
    setItems(result);

}

)

},[]

)
  
return (
<>
  <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
<div className="container-fluid bg-dark px-5 d-none d-lg-block">
<div className="row gx-0">
<div className="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
<div className="d-inline-flex align-items-center" style={{height: "45px"}}>
{item.map(item =>(
<small className="me-3 text-light"><i className="fa fa-map-marker-alt me-2"></i>{item.contact_address}</small>
))}
{item.map(item =>(
<small className="me-3 text-light"><i className="fa fa-phone-alt me-2"></i>{item.contact_phone }</small>
))}
{item.map(item =>(
<small className="text-light"><i className="fa fa-envelope-open me-2"></i>{item.contact_email}</small>
))}
</div>
</div>
<div className="col-lg-4 text-center text-lg-end">
<div className="d-inline-flex align-items-center"style={{height: "45px"}}>

{social.map(social =>(
<a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" href={social.social_url} target="_blank"><i className={social.social_icon}></i></a>
 
))}
</div>
</div>
</div>
</div>

<div className="container-fluid position-relative p-0">
<nav className="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0">
<Link to="/" className="navbar-brand p-0">
<h1 className="m-0">
{item.map(item =>(<img className="w-100" src={item.logo}alt="rankingworlddigital" style={{height:"100px"}}/>

))}</h1>
</Link>
<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
<span className="fa fa-bars"></span>
</button>
<div className="collapse navbar-collapse" id="navbarCollapse">
<div className="navbar-nav ms-auto py-0">
<Link to="/" className="nav-item nav-link" onClick={() => {window.scroll(0, 0);}}>Home</Link>
<Link to="/about-us" className="nav-item nav-link" onClick={() => {window.scroll(0, 0);}}>About</Link>
<Link to="/blog" className="nav-item nav-link " onClick={() => {window.scroll(0, 0);}}>Blog</Link>
<Link to="/contact-us" className="nav-item nav-link " onClick={() => {window.scroll(0, 0);}}>Contact</Link>
 

</div>
 
<Link to="/contact-us" className="btn btn-primary py-2 px-4 ms-3">Enquire Now </Link>
</div>
</nav>
</div>


 
</>
)
}

export  default Header;