import React from "react";
 
import {Helmet} from "react-helmet";
import emailjs from "emailjs-com";
import { Form, Input, TextArea, Button } from "semantic-ui-react";
 
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { useState,useEffect } from "react";
const SERVICE_ID = "service_gzw5sic";
const TEMPLATE_ID = "template_sm5v3f7";
const PUBLIC_KEY = "LVfFw7KJN_uwL6ruy";

const Contact=() =>{
    const[item,setItem]=useState([]);
    useEffect(() =>{
    
    fetch("https://aboutcontentwriting.com/admin/api/setting.php")
    .then(res=>res.json())
    .then(
    (result)=>{
    
         
        setItem(result);
    
    }
    
    )
    
    },[]
    
    )
    const[items,setItemtest]=useState([]);
    useEffect(() =>{
    
    fetch("https://aboutcontentwriting.com/admin/api/contacts.php")
    .then(res=>res.json())
    .then(
    (result)=>{     
      setItemtest(result);
    }
    )
    },[]
    
    )

    const handleOnSubmit = (e) => {
        e.preventDefault();
        emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY)
          .then((result) => {
            console.log(result.text);
            Swal.fire({
              icon: 'success',
              title: 'Message Sent Successfully'
            })
          }, (error) => {
            console.log(error.text);
            Swal.fire({
              icon: error,
              title: error,
              text: error.text,
            })
          });
        e.target.reset()
      };
return (
<>
<Helmet>
{items.map(items =>(
            <title>{items.meta_title}</title>
            
            ))}
            {items.map(items =>(
                <meta name="description" content={items.meta_description}></meta>
            ))}
              {items.map(items =>(
                <meta name="keywords" content={items.meta_keyword}></meta>
            ))}
        </Helmet>

<div class="container-fluid position-relative p-0">
         

         <div class="container-fluid bg-primary py-5 bg-header" style={{marginBottom: "90px"}}>
             <div class="row py-5">
                 <div class="col-12 pt-lg-5 mt-lg-5 text-center">
                     <h1 class="display-4 text-white animated zoomIn">Contact</h1>
                     <a href="#" class="h5 text-white">Home</a>
                     <i class="fa fa-circle text-white px-2"></i>
                     <a href="#" class="h5 text-white">Contact</a>
                 </div>
             </div>
         </div>
     </div>

      
 
 <div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
<div class="container py-5">
<div class="row g-5">
<div class="col-lg-7">
<div class="section-title position-relative pb-3 mb-5">
<h5 class="fw-bold text-primary text-uppercase">Be My Guest</h5>
<h1 class="mb-0">Share your experience and learnings about Content Writing.</h1>
 
<br/>

                            {item.map(item =>(
                            <h4 class="text-primary mb-0">{item.contact_email}</h4>))}
<br/>

                           
                            {item.map(item =>(
                            <h4 class="text-primary mb-0">{item.contact_address}</h4>))}
 <br/>
                          
                        

</div>
<div class="row gx-3">
 

</div>
 
<div class="d-flex align-items-center mt-2 wow zoomIn" data-wow-delay="0.6s">
 
<div class="ps-4">
 
</div>
</div>
</div>
<div class="col-lg-5">
<div class="bg-primary rounded h-100 d-flex align-items-center p-5 wow zoomIn" data-wow-delay="0.9s">
    <div style={{width:"100%"}}>
  <Form onSubmit={handleOnSubmit}>
        <Form.Field
          id='form-input-control-email'
          control={Input}
          label='Email'
          name='user_email'
          placeholder='Email…'
          required
          
          
        />
        <Form.Field
          id='form-input-control-last-name'
          control={Input}
          label='Name'
          name='user_name'
          placeholder='Name…'
          required
          
          
        />
        <Form.Field
          id='form-input-control-last-name'
          control={Input}
          label='Mobile'
          name='user_phone'
          placeholder='Mobile…'
          required
          
           
        />
        <Form.Field
          id='form-input-control-last-name'
          control={Input}
          label='Topic'
          name='user_topic'
          placeholder='Topic…'
          required
          
          
        />
        <Form.Field
          id='form-textarea-control-opinion'
          control={TextArea}
          label='Message'
          name='user_message'
          placeholder='Message…'
          required
        />
        <Button type='submit' color='green'>Submit</Button>
      </Form></div>
</div>
</div>
</div>
</div>
</div>


</>
)
}

export  default Contact;